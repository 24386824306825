<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="{ item: { ...file, ...props.item } }"></wi-list-data-table-actions>
    <td class="justify-left">
      <WiFileListItem
        v-if="!loading"
        :file="file"
        :showFileName="false"
      ></WiFileListItem>
      <v-layout v-else fill-height align-center justify-center ma-0>
        <v-progress-circular indeterminate color="primary lighten-2"></v-progress-circular>
      </v-layout>
    </td>
    <td class="justify-left">
      <span style="color: gray;">
        {{ file.extension }}
      </span>
      <br>
      <strong>
        {{ file.name || '- - -' }}
      </strong>
    </td>
    <td class="justify-left">
      <span>
        {{ file.title || '- - -' }}
      </span>
    </td>
    <td class="justify-left">
      <v-btn color="primary" outline @click="openFileInNewTab()">
        <v-icon>open_in_new</v-icon>
        <span style="padding-left: 10px;">
          Abrir arquivo
        </span>
      </v-btn>
    </td>
  </tr>
</template>
<script>
  import WiFileListItem from '@/default/component/WiFile/WiFileListItem'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Posts',
    data: () => ({
      login: {
        url: '',
        method: 'POST',
        fields: []
      },
      loading: false
    }),
    computed: {
      file () {
        return this.props.item.file || this.props.item
      }
    },
    methods: {
      openFileInNewTab: function () {
        window.open(this.file.url)
      }
    },
    props: {
      config: Object,
      props: Object,
      loading: Boolean
    },
    components: {
      WiFileListItem,
      WiListDataTableActions
    }
  }
</script>
<style>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>